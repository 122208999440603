.product-name,
.product-ingredients,
.product-price,
.product-allergens {
    margin: 0;
    padding: 0;
}

.product-line {
    border-bottom: 1px dashed #808080;
    text-align: left;
    padding: 10px 0;
}
.product-line:last-child {
    border-bottom: none;
}

.product-ingredients {
    color: gray;
    font-size: calc(6px + 2vmin);
}

.product-price {
    float: right;
}

.product-allergens-icon {
    width: 25px;
}

.extra-name,
.extra-price {
    margin: 0;
    padding: 0;
}
