.footer {
    margin-top: 1em;
}

.contact {
    font-size: calc(3px + 2vmin);
    text-align: center;
}

.made-with-love {
    text-align: center;
    margin-top: 2rem;
    font-size: 0.5em;
}
