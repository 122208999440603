.main-allergens {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.main-allergens-item {
    padding: 0 5px;
    text-align: center;
}

.main-allergens-item-icon {
    width: 30px;
}

.main-allergens-item-name {
    color: #808080;
    font-size: calc(1px + 2vmin);
    display: block;
}