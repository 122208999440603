.service-name,
.service-price {
    margin: 0;
    padding: 0;
}

.service-line {
    font-size: calc(2px + 2vmin);
    margin: 5px auto;
    text-align: left;
    width: 50%;
}

.service-price {
    float: right;
}
