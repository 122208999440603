.main {
  background-color: #282c34;
  color: #fff;
  font-size: calc(10px + 2vmin);
  padding: 50px;
  min-height: calc(100vh - 100px);
  text-align: center;
}

.main-title {
  text-align: center;
}

.main-title-text {
  display: inline-block;
  font-size: 5px;
  text-indent: -200vw;
}

.main-title-link {
  display: inline-block;
}

.global-link {
  color: #fff;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}
