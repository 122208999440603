.my-collapse {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.menu-section-title {
  text-align: center;
}

.toggle-status {
  display: inline-block;
  font-size: calc(5px + 2vmin);
  margin-left: 10px;
}

.product-extra-line {
  font-size: calc(2px + 2vmin);
  margin: 5px auto;
  text-align: left;
  width: 30%;
}
